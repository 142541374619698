import RootAxios, { AxiosError, AxiosInstance, AxiosRequestConfig, isAxiosError } from "axios";
import urlJoin from "url-join";

import { getAuthInfo } from "@/components/domains/auth";
import { htmlToast } from "@/components/feedbacks/ToastProvider/utils";
import { logResponse } from "@/configs/logging";
import {
  getAppWebViewCookieValue,
  getPayseSessionHash,
  setPayseSessionHashCookie,
} from "@/utils/cookieUtils";
import { getErrorMessages } from "@/utils/error";

const baseURL =
  typeof window === "undefined"
    ? urlJoin(process.env.INTERNAL_BASE_URL ?? "", "/api/v2")
    : urlJoin(process.env.NEXT_PUBLIC_APP_URL ?? "", "/api/v2");

/**
 * サーバー側とブラウザ側でインスタンスを分ける
 * - サーバー側は毎回新しいインスタンスを生成する
 * - ブラウザ側は一度生成したインスタンスを使いまわす
 */
let browserAxiosInstance: AxiosInstance;
const getAxiosInstance = (): AxiosInstance => {
  if (typeof window === "undefined") {
    const instance = RootAxios.create({
      baseURL,
    });

    instance.interceptors.response.use(logResponse);
    return instance;
  }

  if (!browserAxiosInstance) {
    browserAxiosInstance = RootAxios.create({
      baseURL,
    });
    browserAxiosInstance.interceptors.response.use((response) => {
      // フロントエンド側のみでバックエンドで生成されるsessionHashをCookieをセットする
      if (response.headers["x-payse-session-hash"]) {
        setPayseSessionHashCookie(response.headers["x-payse-session-hash"]);
      }

      return response;
    });
  }

  return browserAxiosInstance;
};

type CustomAxiosInstanceAxiosRequestConfig = AxiosRequestConfig & {
  noAuth?: boolean; // Jwt-Authorizationが不要な場合にtrue
};

const emptyAuth = { accessToken: undefined, isLoggedIn: false };

/**
 * orval用
 */
export const customAxiosInstance = <T>(
  config: AxiosRequestConfig,
  options?: CustomAxiosInstanceAxiosRequestConfig
): Promise<T> => {
  const { noAuth = false, ...restOptions } = options ?? {};
  const { accessToken, isLoggedIn } = noAuth ? emptyAuth : getAuthInfo();
  const appWebViewCookieValue = getAppWebViewCookieValue();
  const source = RootAxios.CancelToken.source();
  const sessionId = getPayseSessionHash();
  const headers = {
    "Content-Type": "application/json",
    ...config.headers,
    ...restOptions?.headers,
    ...(isLoggedIn && { "Jwt-Authorization": `Bearer ${accessToken}` }),
    ...(appWebViewCookieValue && { "X-SALUS-APP-TOKEN": appWebViewCookieValue }),
    ...(sessionId && { "X-PAYSE-SESSION-HASH": sessionId }),
  };

  const AxiosInstance = getAxiosInstance();

  const promise = AxiosInstance({
    ...config,
    ...restOptions,
    headers,
    cancelToken: source.token,
  })
    .then(({ data }) => data)
    .catch((error) => {
      // promiseがキャンセルされた場合はエラーを表示しない
      if (RootAxios.isCancel(error)) {
        return;
      }

      // GETリクエストの場合はエラー表示を統一する、それ以外はthrowする
      if (isAxiosError(error) && error.config?.method === "get") {
        htmlToast.error(getErrorMessages(error));
        /**
         * エラーをthrowせずにundefinedをreturnすると、useQuerySuspenseに異常終了するので、空オブジェクトを返す
         *
         * APIレスポンスエラー ＋ 元々期待されている戻り値がオブジェクトじゃなかったら、
         * レンダリング側でエラーが発生する可能性があるのでご注意ください
         */
        return {};
      }

      throw error;
    });

  // @ts-expect-error promiseがanyなので
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;

/**
 * 静的な関数内で利用可能なaxiosインスタンスを返す
 * @returns
 */
export const getAxios = (config?: AxiosRequestConfig) => {
  const { accessToken, isLoggedIn } = getAuthInfo();
  return RootAxios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      ...(isLoggedIn && { "Jwt-Authorization": `Bearer ${accessToken}` }),
    },
    ...config, // headerの上書きをするため後に置く
  });
};
