import { useMemo } from "react";

import clsx from "clsx";

import styles from "./ResponsiveGrid.module.scss";

type ResponsiveProps<T> = T | { sp: T; pc: T };

export interface ResponsiveGridProps<T extends React.ElementType> {
  children?: React.ReactNode;
  className?: string;
  as?: T;
  // 並べ方、row: 横にcols個並べる、column: 縦にcols個並べる
  cols: ResponsiveProps<number>;
  flow: ResponsiveProps<"row" | "column">;
  gap?: ResponsiveProps<number>;
}

const getResponsiveValues = <T,>(prop: ResponsiveProps<T>): { sp: T; pc: T } => {
  if (typeof prop === "object" && prop != null && "sp" in prop && "pc" in prop) {
    return prop;
  }
  return { sp: prop, pc: prop };
};

export function ResponsiveGrid<T extends React.ElementType = "div">({
  children,
  className,
  as,
  cols,
  flow,
  gap = 0,
}: ResponsiveGridProps<T>): React.ReactNode {
  const Component = as ?? "div";
  const { sp: spCols, pc: pcCols } = getResponsiveValues(cols);
  const { sp: spFlow, pc: pcFlow } = getResponsiveValues(flow);
  const { sp: spGap, pc: pcGap } = getResponsiveValues(gap);

  const gridStyle = useMemo(
    () =>
      ({
        "--grid-gap-sp": `${spGap}px`,
        "--grid-gap-pc": `${pcGap}px`,
        "--grid-cols-sp": spCols,
        "--grid-cols-pc": pcCols,
      }) as React.CSSProperties,
    [spCols, pcCols, spGap, pcGap]
  );

  const totalClassName = useMemo(
    () =>
      clsx(
        styles.root,
        spFlow === "column" ? styles.columnFlowSp : styles.rowFlowSp,
        pcFlow === "column" ? styles.columnFlowPc : styles.rowFlowPc,
        className
      ),
    [className, pcFlow, spFlow]
  );

  return (
    <Component className={totalClassName} style={gridStyle}>
      {children}
    </Component>
  );
}
