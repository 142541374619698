import { Cart, FreePoint, PaidPoint } from "@/generated/open-api/schemas";
import { NuxtCommonCartItem } from "@/storage/types";
import { convertObjToCamelCase, convertObjToSnakeCase } from "@/utils/converters";

import { CartModel, CartItemModel, FreePointModel, PaidPointModel } from "./type";
import { convertApiProductToProduct, convertProductToApiProduct } from "../product/converters";

const parseIntOrZero = (value: string | undefined | number): number => {
  if (value === undefined) return 0;
  if (typeof value === "number") return value;
  const res = parseInt(value);
  if (isNaN(res)) return 0;
  return res;
};

export const convertApiCartToCart = (data: Cart): CartModel => {
  const parsed = convertObjToCamelCase(data);
  const parsedProducts = data?.products?.map(convertApiProductToProduct) ?? [];
  const parsedFreezeProducts = data?.freeze_products?.map(convertApiProductToProduct) ?? [];
  const parsedNormalProducts = data?.normal_products?.map(convertApiProductToProduct) ?? [];
  return {
    ...parsed,
    // price系をすべて整数に変換
    afterSecondCouponDiscounts: parseIntOrZero(parsed.afterSecondCouponDiscounts),
    afterSecondLineTotalPrice: parseIntOrZero(parsed.afterSecondLineTotalPrice),
    afterSecondOnlySubscriptionTotalPrice: parseIntOrZero(
      parsed.afterSecondOnlySubscriptionTotalPrice
    ),
    afterSecondTotalPrice: parseIntOrZero(parsed.afterSecondTotalPrice),
    afterSecondTotalPriceCashDeliveryFee: parseIntOrZero(
      parsed.afterSecondTotalPriceCashDeliveryFee
    ),
    couponDiscount: parseIntOrZero(parsed.couponDiscount),
    firstCouponDiscounts: parseIntOrZero(parsed.firstCouponDiscounts),
    firstLineTotalPrice: parseIntOrZero(parsed.firstLineTotalPrice),
    firstOnlySubscriptionTotalPrice: parseIntOrZero(parsed.firstOnlySubscriptionTotalPrice),
    firstSubscriptionTotalPrice: parseIntOrZero(parsed.firstSubscriptionTotalPrice),
    firstSubscriptionTotalPriceCashDeliveryFee: parseIntOrZero(
      parsed.firstSubscriptionTotalPriceCashDeliveryFee
    ),
    freezeProductTotalPrice: parseIntOrZero(parsed.freezeProductTotalPrice),
    freezeShippingFee: parseIntOrZero(parsed.freezeShippingFee),
    ifChangeSubscriptionAfterSecondLineTotalPrice: parseIntOrZero(
      parsed.ifChangeSubscriptionAfterSecondLineTotalPrice
    ),
    ifChangeSubscriptionAfterSecondTotalPrice: parseIntOrZero(
      parsed.ifChangeSubscriptionAfterSecondTotalPrice
    ),
    ifChangeSubscriptionAfterSecondTotalPriceCashDeliveryFee: parseIntOrZero(
      parsed.ifChangeSubscriptionAfterSecondTotalPriceCashDeliveryFee
    ),
    ifChangeSubscriptionFirstLineTotalPrice: parseIntOrZero(
      parsed.ifChangeSubscriptionFirstLineTotalPrice
    ),
    ifChangeSubscriptionFirstTotalPrice: parseIntOrZero(parsed.ifChangeSubscriptionFirstTotalPrice),
    ifChangeSubscriptionFirstTotalPriceCashDeliveryFee: parseIntOrZero(
      parsed.ifChangeSubscriptionFirstTotalPriceCashDeliveryFee
    ),
    lineTotalPrice: parseIntOrZero(parsed?.lineTotalPrice),
    normalCouponDiscounts: parseIntOrZero(parsed?.normalCouponDiscounts),
    normalLineTotalPrice: parseIntOrZero(parsed?.normalLineTotalPrice),
    normalProductTotalPrice: parseIntOrZero(parsed?.normalProductTotalPrice),
    normalShippingFee: parseIntOrZero(parsed?.normalShippingFee),
    normalTotalPrice: parseIntOrZero(parsed?.normalTotalPrice),
    normalTotalPriceCashDeliveryFee: parseIntOrZero(parsed?.normalTotalPriceCashDeliveryFee),
    totalPriceCashDeliveryFee: parseIntOrZero(parsed?.totalPriceCashDeliveryFee),
    totalPrice: parseIntOrZero(parsed?.totalPrice),
    totalDefaultShippingFee: parseIntOrZero(parsed?.totalDefaultShippingFee),
    totalShippingFee: parseIntOrZero(parsed?.totalShippingFee),
    paidTotalPoint: parseIntOrZero(parsed?.paidTotalPoint),
    metadata: {
      setNames: (data?.metadata as unknown as { set_names: string[] })?.set_names ?? [],
    },
    products: parsedProducts,
    freezeProducts: parsedFreezeProducts,
    normalProducts: parsedNormalProducts,
    /**
     * カートの最終的な合計価格
     */
    totalPriceWithPointDiscount:
      Number(parsed.totalPrice) + // 商品金額に配達料とクーポン・ポイント割引を含めた料金
      Number(parsed.totalPriceCashDeliveryFee), // 手数料（代引き）
    /**
     * カートの最終的な合計価格からクーポン・ポイント適用前の価格
     */
    totalPriceWithNoPointDiscount:
      Number(parsed.lineTotalPrice) + // totalPriceから配達料とクーポン・ポイント割引を除いた料金
      Number(parsed.totalPriceCashDeliveryFee) + // 手数料（代引き）
      Number(parsed.totalShippingFee),
    totalGetMile: parseIntOrZero(parsed.totalGetMile),
    mileTargetPrice: Math.max(
      parseIntOrZero(
        Math.max(
          parseIntOrZero(parsed.normalProductTotalPrice),
          parseIntOrZero(parsed.freezeProductTotalPrice)
        )
      ) - parseIntOrZero(parsed.couponDiscount),
      0
    ),
  };
};

export const convertCartToApiCart = (cart: CartModel): Cart => {
  const parsed = convertObjToCamelCase(cart);
  const parsedProducts = cart?.products?.map(convertProductToApiProduct) ?? [];
  const parsedFreezeProducts = cart?.freezeProducts?.map(convertProductToApiProduct) ?? [];
  const parsedNormalProducts = cart?.normalProducts?.map(convertProductToApiProduct) ?? [];
  const metadata = cart.metadata.setNames;

  return {
    ...parsed,
    points: {
      default_free_points: cart.points?.defaultFreePoints?.map(convertCartPointsToApiCartPoints),
      forty_five_days_free_points: cart.points?.fortyFiveDaysFreePoints?.map(
        convertCartPointsToApiCartPoints
      ),
      light_paid_points: cart.points?.lightPaidPoints?.map(convertPaidPointToApiPaidPoint),
      regular_paid_points: cart.points?.regularPaidPoints?.map(convertPaidPointToApiPaidPoint),
    },
    after_second_coupon_discounts: String(cart.afterSecondCouponDiscounts),
    after_second_line_total_price: String(cart.afterSecondLineTotalPrice),
    after_second_only_subscription_total_price: String(cart.afterSecondOnlySubscriptionTotalPrice),
    after_second_total_price: String(cart.afterSecondTotalPrice),
    after_second_total_price_cash_delivery_fee: String(cart.afterSecondTotalPriceCashDeliveryFee),
    coupon_discount: String(cart.couponDiscount),
    first_coupon_discounts: String(cart.firstCouponDiscounts),
    first_line_total_price: String(cart.firstLineTotalPrice),
    first_only_subscription_total_price: String(cart.firstOnlySubscriptionTotalPrice),
    first_subscription_total_price: String(cart.firstSubscriptionTotalPrice),
    first_subscription_total_price_cash_delivery_fee: String(
      cart.firstSubscriptionTotalPriceCashDeliveryFee
    ),
    freeze_product_total_price: String(cart.freezeProductTotalPrice),
    freeze_shipping_fee: String(cart.freezeShippingFee),
    if_change_subscription_after_second_line_total_price: String(
      cart.ifChangeSubscriptionAfterSecondLineTotalPrice
    ),
    if_change_subscription_after_second_total_price: String(
      cart.ifChangeSubscriptionAfterSecondTotalPrice
    ),
    if_change_subscription_after_second_total_price_cash_delivery_fee: String(
      cart.ifChangeSubscriptionAfterSecondTotalPriceCashDeliveryFee
    ),
    if_change_subscription_first_line_total_price: String(
      cart.ifChangeSubscriptionFirstLineTotalPrice
    ),
    if_change_subscription_first_total_price: String(cart.ifChangeSubscriptionFirstTotalPrice),
    if_change_subscription_first_total_price_cash_delivery_fee: String(
      cart.ifChangeSubscriptionFirstTotalPriceCashDeliveryFee
    ),
    line_total_price: String(cart.lineTotalPrice),
    normal_coupon_discounts: String(cart.normalCouponDiscounts),
    normal_line_total_price: String(cart.normalLineTotalPrice),
    normal_product_total_price: String(cart.normalProductTotalPrice),
    normal_shipping_fee: String(cart.normalShippingFee),
    normal_total_price: String(cart.normalTotalPrice),
    normal_total_price_cash_delivery_fee: String(cart.normalTotalPriceCashDeliveryFee),
    total_price_cash_delivery_fee: String(cart.totalPriceCashDeliveryFee),
    total_price: String(cart.totalPrice),
    total_default_shipping_fee: String(cart.totalDefaultShippingFee),
    total_shipping_fee: String(cart.totalShippingFee),
    metadata,
    products: parsedProducts,
    freeze_products: parsedFreezeProducts,
    normal_products: parsedNormalProducts,
  };
};

export const convertStoreCartItemToCartItems = (
  storeCartItems: NuxtCommonCartItem[]
): CartItemModel[] => {
  return storeCartItems.map((item) => ({
    variantId: parseInt(item.variant_id),
    quantity: item.quantity,
    subscription: item.subscription,
  }));
};

export const convertCartItemsToStoreCartItems = (
  cartItems: CartItemModel[]
): NuxtCommonCartItem[] => {
  return cartItems.map((item) => ({
    variant_id: String(item.variantId),
    quantity: item.quantity,
    subscription: item.subscription,
  }));
};

export const convertCartPointsToApiCartPoints = (freePoint: FreePointModel): FreePoint => {
  const parsed = convertObjToSnakeCase(freePoint);
  return {
    ...parsed,
    created_at:
      freePoint.createdAt && parsed.created_at
        ? {
            ...freePoint.createdAt,
            timezone: parsed.created_at.timezone,
          }
        : undefined,
  };
};

export const convertPaidPointToApiPaidPoint = (freePoint: PaidPointModel): PaidPoint => {
  const parsed = convertObjToSnakeCase(freePoint);
  return {
    ...parsed,
    created_at:
      freePoint.createdAt && parsed.created_at
        ? {
            ...freePoint.createdAt,
            timezone: parsed.created_at.timezone,
          }
        : undefined,
  };
};
