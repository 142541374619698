"use client";
import React, { useEffect, useState } from "react";

import clsx from "clsx";
import Image, { ImageProps } from "next/image";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import styles from "./IndicatorSlider.module.scss";

export interface IndicatorSliderProps extends React.ComponentProps<"div"> {
  images: ImageProps[];
}

export function IndicatorSlider({ images, className, ...divProps }: IndicatorSliderProps) {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  useEffect(() => {
    swiper?.on("slideChange", (a) => {
      setCurrentIndex(a.activeIndex);
    });
    return () => swiper?.off("slideChange");
  }, [swiper]);

  return (
    <div className={clsx(styles.root, className)} {...divProps}>
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
          bulletClass: styles.bullet,
          bulletActiveClass: styles.bulletActive,
        }}
        modules={[Pagination]}
        className={styles.mainSwiper}
        onSwiper={setSwiper}
      >
        {images.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <Image {...image} alt={image.alt} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={styles.counter}>
        {currentIndex + 1} / {images.length}
      </div>
    </div>
  );
}
