import { useMemo } from "react";

import { htmlToast } from "@/components/feedbacks/ToastProvider/utils";
import { postCart } from "@/generated/axios-functions/payseAPI";
import { useGetCart, useGetCartSuspense } from "@/generated/open-api/cart/cart";
import { PostCartBody } from "@/generated/open-api/schemas";
import { CartCalcApiResponse, CartProductItem, GetCartParams } from "@/generated/open-api/schemas";
import { convertApiCartToCart } from "@/models/cart/converters";
import { SnakeToCamelCaseNested } from "@/utils";
import { convertObjToSnakeCase } from "@/utils/converters";
import { getErrorMessages } from "@/utils/error";

export type GetParsedCartParams = SnakeToCamelCaseNested<PostCartBody>;

export async function getParsedCart(params: GetParsedCartParams) {
  try {
    const res = (await postCart(convertObjToSnakeCase(params))).cart;
    if (!res) return undefined;
    return convertApiCartToCart(res);
  } catch (e) {
    htmlToast.error(getErrorMessages(e));
  }
}

/**
 * productsをObjectの配列から文字列に変換する必要がある
 */
type UseGetParsedCartParams = Omit<GetCartParams, "products"> & {
  products: CartProductItem[];
};

export function useGetParsedCart(
  params: UseGetParsedCartParams,
  options?: Parameters<typeof useGetCart>[1]
) {
  // productsをObjectから文字列に変換
  const paramsFormatted: GetCartParams = useMemo(() => {
    return {
      ...params,
      products: JSON.stringify(params.products),
    };
  }, [params]);

  const { data: dataUnknown, ...rest } = useGetCart(paramsFormatted, options);
  // optionsをuseGetCartに渡すと、dataがunknownになるので型を強制的に変換
  const data = dataUnknown as CartCalcApiResponse | undefined;

  const cart = useMemo(() => {
    if (!data?.cart) return;
    return convertApiCartToCart(data.cart);
  }, [data]);

  return { data: cart, ...rest };
}

export function useGetParsedCartSuspense(
  params: UseGetParsedCartParams,
  options?: Parameters<typeof useGetCartSuspense>[1]
) {
  // productsをObjectから文字列に変換
  const paramsFormatted = useMemo(() => {
    return {
      ...params,
      products: JSON.stringify(params.products),
    };
  }, [params]);

  const { data: dataUnknown, ...rest } = useGetCartSuspense(paramsFormatted, options);
  const data = dataUnknown as CartCalcApiResponse;

  const cart = useMemo(() => {
    if (!data.cart) return;
    return convertApiCartToCart(data.cart);
  }, [data]);

  return { data: cart, ...rest };
}
