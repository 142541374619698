import { useOnce } from "./useOnce";

const anygiftScriptSrc = "https://d1ioo46r7yo3cy.cloudfront.net/store.js";
const storeId = process.env.NEXT_PUBLIC_APP_ENV !== "production" ? "basefood-dev" : "basefood";

export const useInitAnygift = (loadAnygift = true) => {
  useOnce(() => {
    if (!loadAnygift) return;

    window.AnyGift = { storeId };

    // 既存のスクリプトを削除
    const existingScripts = document.querySelectorAll(`script[src="${anygiftScriptSrc}"]`);
    existingScripts.forEach((script) => script.remove());

    // スクリプトを追加によってAnygiftを初期化
    const script = document.createElement("script");
    script.src = anygiftScriptSrc;
    script.defer = true;

    document.body.appendChild(script);
  }, [loadAnygift]);
};
