"use client";
import { useState, useCallback } from "react";

import clsx from "clsx";
import { Tooltip } from "react-tooltip";

import { Column } from "@/components/containers";
import { Modal } from "@/components/feedbacks";
import { Button } from "@/components/inputs";

import { useTooltipId } from "./helpers";
import styles from "./TooltipIcon.module.scss";

export interface TooltipIconProps {
  id?: string;
  className?: string;
  tooltipTitle?: string;
  tooltips: React.ReactNode;
  pcTooltips?: React.ReactNode;
  tooltipCloseButtonText: string;
}

export function TooltipIcon({
  id,
  className,
  tooltipTitle,
  tooltips,
  pcTooltips,
  tooltipCloseButtonText,
}: TooltipIconProps): React.ReactNode {
  const tooltipId = useTooltipId(id);
  const [isTooltipModalOpen, setIsTooltipModalOpen] = useState(false);

  const toggleTooltipModal = useCallback(() => {
    setIsTooltipModalOpen((prev) => !prev);
  }, []);

  return (
    <>
      <div className="clear__sp">
        <i
          id={tooltipId}
          className={clsx("fas fa-question-circle text__m", styles.icon, className)}
        />
        <Tooltip anchorSelect={`#${tooltipId}`} className={styles.tooltip} clickable>
          {pcTooltips ?? tooltips}
        </Tooltip>
      </div>
      <div className="clear__pc">
        <i
          className={clsx("fas fa-question-circle text__m", styles.icon, className)}
          id={tooltipId}
          onClick={toggleTooltipModal}
        />
        <Modal
          open={isTooltipModalOpen}
          closeModal={toggleTooltipModal}
          displayCloseButton={false}
          footer={
            <Button type="button" variants="gray" onClick={toggleTooltipModal} className="text__m">
              {tooltipCloseButtonText}
            </Button>
          }
        >
          {tooltipTitle && <p className="text__l text__bold">{tooltipTitle}</p>}
          <Column className={clsx("text__m mg__top__m")}>{tooltips}</Column>
        </Modal>
      </div>
    </>
  );
}
