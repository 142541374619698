import React, { ComponentType, ReactNode, Suspense } from "react";

export const withSuspense = <P extends object>(
  Component: ComponentType<P>,
  fallback: ReactNode = null
) => {
  const WrappedComponent = (props: P) => (
    <Suspense fallback={fallback}>
      <Component {...props} />
    </Suspense>
  );
  WrappedComponent.displayName = Component.displayName;
  return WrappedComponent;
};

export const testSuspenseFallbackId = "test-suspense-fallback";
export const testSuspenseFallback = <div data-testid={testSuspenseFallbackId} />;
