export const numberFormat = (val: unknown) => {
  if (!val) {
    return "0";
  }
  if (val) {
    return val.toLocaleString();
  }
};

// 繰り上げ
export function roundUp(value: number, base: number = 1): number {
  return Math.ceil(value / base) * base;
}
